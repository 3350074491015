import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import Traveler from '~/models/Traveler'
import Itinerary from '~/models/Itinerary'
import { ProfileService } from '~/passporter-services/profile/service'

interface TravelerInitState {
	traveler?: Traveler
}

const initState = (): TravelerInitState => ({
	traveler: undefined,
})

export const state = initState
export type TravelerModuleState = ReturnType<typeof state>

export const mutations: MutationTree<TravelerModuleState> = {
	setTraveler(state, traveler: Traveler) {
		state.traveler = traveler
	},
	setItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.itinerariesNextPage = nextPage
			state.traveler.itineraries = itineraries
		}
	},
	setPreviousItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.previousItinerariesNextPage = nextPage
			state.traveler.previousItineraries = itineraries
		}
	},
	setOngoingItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.ongoingItinerariesNextPage = nextPage
			state.traveler.ongoingItineraries = itineraries
		}
	},
	setFollowingItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.followingItinerariesNextPage = nextPage
			state.traveler.followingItineraries = itineraries
		}
	},
	pushItineraries(state, { nextPage, itineraries }) {
		if (state.traveler) {
			state.traveler.pushItineraries(itineraries, nextPage)
		}
	},
	setSubscribed(state, subscribed: boolean) {
		if (state.traveler) {
			state.traveler.subscribed = subscribed
		}
	},
}
export const actions: ActionTree<TravelerModuleState, RootState> = {
	async getTraveler({ commit, dispatch }, travelerId: string) {
		try {
			const traveler = await ProfileService.getTraveler(travelerId)
			commit('setTraveler', traveler)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraries({ commit, dispatch, state }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
			})
			commit('setItineraries', { nextPage: next, itineraries: items })
		} catch (e) {
			commit('setItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getPreviousItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
				status: 'previous',
			})
			commit('setPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setPreviousItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getOngoingItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
				status: 'ongoing',
			})
			commit('setOngoingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setOngoingItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getFollowingItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: travelerId ?? state.traveler?.id,
				page: 0,
				status: 'following',
			})
			commit('setFollowingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setFollowingItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreItineraries({ commit, state, dispatch }, travelerId: string) {
		try {
			if (state.traveler && state.traveler.itinerariesNextPage) {
				const { next, items } = await ProfileService.getItineraries({
					id: travelerId ?? state.traveler?.id,
					page: state.traveler.itinerariesNextPage,
				})
				commit('pushItineraries', {
					nextPage: next,
					itineraries: items,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openTraveler(
		{ dispatch },
		{
			traveler,
			travelerId,
		}: {
			traveler?: Traveler
			travelerId?: string
		}
	) {
		try {
			const route = `/${this.$i18n.locale}/profiles/${traveler?.id || travelerId}`

			await this.$router.push(route)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getSubscription({ commit, state, dispatch }, travelerId: string) {
		try {
			const isSubscribed = await ProfileService.isSubscribedToUser(travelerId ?? state.traveler?.id)
			commit('setSubscribed', isSubscribed)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async subscribeToTraveler({ state, dispatch }, travelerId: string) {
		try {
			await ProfileService.subscribeToUser(travelerId ?? state.traveler?.id)
			dispatch('getSubscription', travelerId)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async unsubscribeToTraveler({ state, dispatch }, travelerId: string) {
		try {
			await ProfileService.unsubscribeToUser(travelerId ?? state.traveler?.id)
			dispatch('getSubscription', travelerId)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
}
export const getters: GetterTree<TravelerModuleState, RootState> = {
	traveler: (state): Traveler | undefined => {
		return state.traveler
	},
	subscribed: (state): boolean => {
		return state.traveler?.subscribed ?? false
	},

	itineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.itineraries
	},
	previousItineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.previousItineraries
	},
	ongoingItineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.ongoingItineraries
	},
	followingItineraries: (state): Itinerary[] | undefined => {
		return state.traveler?.followingItineraries
	},
	moreItineraries: (state) => {
		return state.traveler?.itinerariesNextPage
	},
}
