




























import Vue, { PropOptions } from 'vue'
import GoogleBrowser from '../shared/browser/GoogleBrowser.vue'
import ProfileName from '~/components/context/profile/ProfileName.vue'
import ProfilePicture from '~/components/context/profile/ProfilePicture.vue'
import Username from '~/components/context/profile/Username.vue'
import YearPicker from '~/components/PassporterUI/YearPicker.vue'
import Form from '~/components/UI/Form.vue'
import { Destination2 } from '~/models/Destination'
import { Profile } from '~/models/Profile'
import { Spot2 } from '~/models/Spot'

export default Vue.extend({
	name: 'ProfileForm',
	components: { Form, ProfileName, YearPicker, ProfilePicture, Username, GoogleBrowser },
	props: {
		profile: {
			type: Object,
			required: true,
		} as PropOptions<Profile>,
		isMobile: {
			type: Boolean,
			required: true,
		},
		usernameError: {
			type: String,
			default: '',
		},
		profileNameError: {
			type: String,
			default: '',
		},
		yearError: {
			type: String,
			default: '',
		},
		countryError: {
			type: String,
			default: '',
		},
	},
	data() {
		const form: Profile = { ...this.profile }
		return {
			form,
		}
	},
	methods: {
		selectPicture(picture: { imageUrl: string; mediaId: string } | undefined) {
			this.form.avatarId = picture?.mediaId
			this.form.avatar = picture?.imageUrl
			this.$emit('change', this.form)
		},
		profileNameChange(text: string) {
			this.form.name = text
			this.$emit('change', this.form)
		},
		usernameChange(text: string) {
			this.form.username = text
			this.$emit('change', this.form)
		},
		yearSelected(year: string) {
			this.form.birthYear = year
			this.$emit('change', this.form)
		},
		selectCountry(country: Spot2 | Destination2) {
			this.form.country = country.id!
			this.form.countryName = country.name!
			this.$emit('change', this.form)
		},
	},
})
