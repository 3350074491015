import { BaseRepository } from '../baseRepository'
import { ApiItinerary } from '../itinerary/apiTypes'
import { ItineraryEntityMapper } from '../itinerary/entityMapper'
import { ApiPage, toPage } from '../shared/pagination'
import ProfileApiMapper from './apiMapper'
import { ApiAvailability, ApiProfile, ApiSubscription } from './apiTypes'
import { ProfileEntityMapper } from './entityMapper'
import Traveler from '~/models/Traveler'
import { Profile, Subscription } from '~/models/Profile'
import { Itinerary2, ItineraryStatus } from '~/models/Itinerary'
import { Page } from '~/models/PaginatedItems'

export class ProfileRepository extends BaseRepository {
	static resource = 'v1/profiles'

	static async getProfile(id: string): Promise<Profile> {
		const response: ApiProfile = await this.get(`${this.resource}/${id}/`)
		return ProfileEntityMapper.toProfile(response!)
	}

	static async getTraveler(id: string): Promise<Traveler> {
		const response: ApiProfile = await this.get(`${this.resource}/${id}/`)
		return ProfileEntityMapper.toTraveler(response!)
	}

	static async updateProfile(id: string, profile: Profile): Promise<Profile> {
		const response: ApiProfile | undefined = await this.patch(
			`${this.resource}/${id}/`,
			ProfileApiMapper.toApiUpdateProfile(profile)
		)
		return ProfileEntityMapper.toProfile(response!)
	}

	static async getItineraries({
		id,
		page,
		status = 'all',
	}: {
		id: string
		page: number
		status?: ItineraryStatus
	}): Promise<Page<Itinerary2>> {
		const response: ApiPage<ApiItinerary> = await this.get(`${this.resource}/${id}/itineraries/`, {
			travel_status: status,
			page,
			page_size: 16,
		})
		const itineraries = ItineraryEntityMapper.toItineraries(response!.results)
		return toPage(response!, itineraries)
	}

	static async getSubscription(userId: string): Promise<Subscription> {
		const response: ApiSubscription = await this.get(`${this.resource}/${userId}/subscription/`)
		return ProfileEntityMapper.toSubscription(response!)
	}

	static async subscribe(userId: string): Promise<void> {
		return await this.post(`${this.resource}/${userId}/subscription/`)
	}

	static async unsubscribe(userId: string): Promise<void> {
		return await this.delete(`${this.resource}/${userId}/subscription/`)
	}

	static async checkUsernameAvailability(username: string): Promise<boolean> {
		const params = { username: ProfileApiMapper.toApiAvailability(username) }
		const response: ApiAvailability = await this.get(`auth/user/check/`, params)
		return ProfileEntityMapper.toAvailable(response)
	}

	static async addWishlist(userId: string, wishlist: Array<string>): Promise<void> {
		await this.post(`${this.resource}/${userId}/destinations/wishlist/`, {
			destination_ids: wishlist,
		})
	}

	static async addVisitedList(userId: string, visitedList: Array<string>): Promise<void> {
		await this.post(`${this.resource}/${userId}/destinations/visited/`, {
			destination_ids: visitedList,
		})
	}
}
