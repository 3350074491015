import Cookies from 'js-cookie'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Profile } from '~/models/Profile'
import { ProfileService } from '~/passporter-services/profile/service'
import { AuthService } from '~/services/auth/service'
import { RootState } from '~/store/index'

const initState = () => ({
	accessToken: undefined,
	refreshToken: undefined as string | undefined,
	expires: undefined,
	itineraryDialog: false,
	id: undefined as string | undefined,
	user: undefined as Profile | undefined,
	showAppBanner: true,
	onboards: [] as string[],
})
export const state = initState

export type AuthModuleState = ReturnType<typeof state>
export const mutations: MutationTree<AuthModuleState> = {
	setId(state, id) {
		state.id = id
	},
	setUser(state, user: Profile) {
		state.user = user
	},
	setPayload(state, { access, refresh, exp }) {
		state.accessToken = access
		state.refreshToken = refresh
		state.expires = exp
	},
	setShowAppBanner(state, value) {
		state.showAppBanner = value
	},
	itineraryDialog(state, isOpen: boolean) {
		state.itineraryDialog = isOpen
	},
	pushOnboard(state, key: string) {
		if (!state.onboards.includes(key)) {
			state.onboards.push(key)
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<AuthModuleState, RootState> = {
	async setItinerayDialog({ commit, dispatch }, { isOpen } = {}) {
		try {
			commit('itineraryDialog', isOpen)
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async hideAppBanner({ commit, dispatch }) {
		try {
			commit('setShowAppBanner', false)
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async openLogin({ dispatch }) {
		try {
			await this.$router.replace({
				path: this.$navigation.home.loginRoute(),
			})
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async openRegister({ dispatch }, { leadId }: { leadId: string }) {
		try {
			let path = `/${this.$i18n.locale}/auth/signup`
			if (leadId) {
				path += `?lead_id=${leadId}`
			}
			await this.$router.replace({
				path,
			})
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async login({ commit, dispatch }, { email, password, token }) {
		try {
			const service = new AuthService(this.$repositories.auth)
			const response = await service.login({ email, password, token })
			if (response) {
				commit('setId', response.id)
				commit('setPayload', {
					access: response.access,
					refresh: response.refresh,
					exp: response.exp,
				})
				dispatch('logged', response.id)
			}
		} catch (e: any) {
			const failed = e.response?.data?.code === 'authentication_failed'
			if (failed) {
				e.formErrors = { email: ' ', password: ' ' }
				throw e
			}
			await dispatch('error', e, { root: true })
		}
	},

	async login2({ commit, dispatch }, response) {
		commit('setId', response.id)
		commit('setPayload', {
			access: response.access,
			refresh: response.refresh,
			exp: response.exp,
		})
		await dispatch('logged', response.id)
	},
	async loginOAuth({ commit, dispatch }, { provider, token }) {
		let ref
		const clickId = Cookies.getJSON('tapfiliate-click_id')
		if (!clickId) {
			const params = new URLSearchParams(window.location.search)
			if (params.has('ref')) {
				ref = params.get('ref')
			}
		}
		const metricsUrl = Cookies.get('metrics_url')
		try {
			const service = new AuthService(this.$repositories.auth)
			const response = await service.loginOAuth(provider, token, clickId, ref, metricsUrl)
			if (response) {
				commit('setId', response.id)
				commit('setPayload', {
					access: response.access,
					refresh: response.refresh,
					exp: response.exp,
				})
				dispatch('logged', response.id)
			}
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async logged({ dispatch }, id) {
		if (process.client) {
			window.postMessage({ type: 'passporter::loginDone' }, '*')
			await this.$mixpanel?.identify(id)
		}
		dispatch('setUser')
	},
	registered() {
		try {
			// @ts-ignore
			fbq('track', 'CompleteRegistration')
			// @ts-ignore
			ga(
				'send',
				'event',
				'registration',
				'complete',
				// @ts-ignore
				this.$device.isAndroid
					? 'android'
					: // @ts-ignore
					this.$device.isIos
					? 'ios'
					: undefined
			)

			let ref
			const params = new URLSearchParams(window.location.search)
			if (params.has('ref')) {
				ref = params.get('ref')
			}
			// @ts-ignore
			window.dataLayer = window.dataLayer || []
			// @ts-ignore
			window.dataLayer.push({
				event: 'registrationComplete',
				ref,
			})
		} catch (e) {}
	},
	setProfile({ commit }, profile) {
		commit('setUser', profile)
	},

	async setUser({ commit, state, dispatch }) {
		try {
			if (state.id) {
				const user = await ProfileService.getProfile(state.id)
				commit('setUser', user)
			}
		} catch (e: any) {
			await dispatch('clear')
			await dispatch('error', e, { root: true })
		}
	},
	async register({ commit, dispatch }, { email, password }) {
		let ref
		const clickId = Cookies.getJSON('tapfiliate-click_id')
		if (!clickId) {
			const params = new URLSearchParams(window.location.search)
			if (params.has('ref')) {
				ref = params.get('ref')
			}
		}
		const metricsUrl = Cookies.get('metrics_url')
		try {
			const service = new AuthService(this.$repositories.auth)
			const { id } = await service.register(email, password, clickId, ref, metricsUrl)
			commit('setId', id)
			await dispatch('registered')
		} catch (e: any) {
			const errorCode = e?.response?.data?.code

			if (errorCode) {
				let errorType = errorCode
				e.formErrors = {}

				const duplicateEmail = errorType === 'already_exists'
				if (duplicateEmail) {
					e.formErrors.email = 'error_already_exists'
				}

				const errors = e.response.data.errors || []
				const invalidPasswordError = errors.some(
					(error: { code: string }) => error.code === 'invalid_password'
				)

				if (invalidPasswordError) {
					errorType = 'invalid_password'
					e.formErrors.password = 'E_error_invalid_password'
				}

				this.$mixpanel?.track('Registration Error', {
					error_type: errorType,
				})
			}
			await dispatch('error', e, { root: true })
		}
	},
	async refresh({ commit, state, dispatch }) {
		try {
			const service = new AuthService(this.$repositories.auth)
			const { refreshToken } = state
			if (refreshToken) {
				const { access, refresh } = await service.refresh(refreshToken)
				commit('setPayload', { access, refresh })
			}
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	async availableUserName({}, username) {
		try {
			return await ProfileService.checkUsernameAvailability(username)
		} catch (e: any) {}
	},
	async update({ commit, state, dispatch }, profile: Profile) {
		try {
			if (state.id) {
				const user = await ProfileService.updateProfile(state.id, profile)
				commit('setUser', user)
			}
		} catch (e: any) {
			if (e.response?.data?.errors) {
				e.formErrors = {}
				const invalidUsername = e.response.data.errors.find((error: { code: string }) => {
					return error.code === 'invalid_username'
				})
				if (invalidUsername) {
					e.formErrors.username = invalidUsername.detail
				}
			}
			await dispatch('error', e, { root: true })
		}
	},
	reset({ commit }) {
		commit('reset')
	},
	async clear({ dispatch }) {
		await dispatch('reset')
		await dispatch('alerts/reset', null, { root: true })
		await dispatch('profile/reset', null, { root: true })
		await dispatch('destination/reset', null, { root: true })
		await dispatch('itinerary/reset', null, { root: true })
		dispatch('spot/reset', null, { root: true })
		await dispatch('media/reset', null, { root: true })
		await dispatch('maps/reset', null, { root: true })
		if (process.client) {
			window.postMessage({ type: 'passporter::logoutDone' }, '*')
		}
	},
	async logout({ dispatch }) {
		try {
			this.$mixpanel?.reset()
			await this.$repositories.auth.logout()
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		} finally {
			await dispatch('clear')
		}
	},
	async sendResetPasswordEmail({ dispatch }, { email }) {
		try {
			await this.$repositories.auth.sendResetPasswordEmail(email)
		} catch (e: any) {
			const errorCode = e.response?.data?.code
			if (errorCode) {
				e.formErrors = {}

				if (errorCode === 'user_not_found') {
					e.formErrors = {
						email: 'E_error_invalid_email',
					}
				}

				const erorrs = e.response?.data?.errors
				if (erorrs) {
					const findUserErrorType = erorrs.some(
						(error: { code: string }) =>
							error.code === 'invalid_email' || error.code === 'user_not_found'
					)

					if (findUserErrorType) {
						e.formErrors.email = 'E_error_invalid_email'
					}
				}
			}
			await dispatch('error', e, { root: true })
		}
	},
	async resetPassword({ dispatch }, { email, code, password }) {
		try {
			await this.$repositories.auth.resetPassword({ email, code, password })
		} catch (e: any) {
			if (e && e.response?.data) {
				const errors = e.response.data.errors

				if (errors) {
					e.formErrors = {}
					const invalidPasswordError = errors.some(
						(error: any) => error.code === 'invalid_password'
					)

					if (invalidPasswordError) {
						e.formErrors.password = 'R_reset_password_subtittle'
					}
				}

				const errorCode = e.response.data.code
				if (errorCode) {
					const dispatchErrorCode = async (errorType: string) => {
						await dispatch('alerts/setErrorI18n', { index: errorType }, { root: true })
						throw e
					}

					if (errorCode === 'recover_code_not_found') {
						await dispatchErrorCode('E_error_invalid_code')
					}

					if (errorCode === 'user_not_found') {
						await dispatchErrorCode('error_user_not_found')
					}
				}
			}
			await dispatch('error', e, { root: true })
		}
	},
	onboardViewed({ commit }, key) {
		if (key) {
			commit('pushOnboard', key)
		}
	},
}

export const getters: GetterTree<AuthModuleState, RootState> = {
	isAuthenticated: (state) => {
		return state.accessToken && state.accessToken !== ''
	},
	user: (state) => {
		return state.user
	},
	itineraryDialog: (state) => {
		return state.itineraryDialog
	},
	userId: (state) => {
		return state.id
	},
	showAppBanner: (state) => {
		return state.showAppBanner
	},
	onboards: (state) => {
		return state.onboards
	},
}
