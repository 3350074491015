const resource = 'v1/profiles'
export default (http: any) => ({
	spots({ id, page, visited }: { id: string; page: number; visited?: boolean }) {
		return http.get(`${resource}/${id}/spots/`, {
			params: {
				page,
				page_size: 16,
				visited,
			},
		})
	},
})
