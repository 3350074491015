import Media from '~/models/Media'

export class MediaEntityMapper {
	static toMedia = (media: any) => {
		return new Media({
			created_at: media.created,
			id: media.id,
			metadata: media.metadata,
			name: media.name,
			ownerId: media.owner_id,
			size: media.size,
			state: media.state,
			type: media.type,
			url: media.url,
			pending: media.pending,
		})
	}

	static toMedias = (profiles: any[]) => {
		return profiles.map((profile: any) => this.toMedia(profile))
	}
}
