import { ApiAvailability, ApiProfile, ApiSubscription } from './apiTypes'
import Traveler from '~/models/Traveler'
import { Profile, Subscription } from '~/models/Profile'

export class ProfileEntityMapper {
	static toProfile(profile: ApiProfile): Profile {
		return {
			id: profile.id,
			name: profile.name,
			username: profile.username,
			cover: profile.cover_url,
			avatar: profile.picture_url,
			email: profile.email,
			location: profile.location_name,
			birthYear: profile.birth_date,
		}
	}

	static toProfiles(profiles: ApiProfile[]): Profile[] {
		return profiles.map((profile: ApiProfile) => this.toProfile(profile))
	}

	static toTraveler(traveler: any) {
		return new Traveler({
			id: traveler.user_id ?? traveler.id,
			name: traveler.name,
			username: traveler.username,
			picture: traveler.picture ?? traveler.picture_url,
			role: traveler.role,
			email: traveler.email,
			language: traveler.language,
		})
	}

	static toTravelers(travelers: any[]) {
		return travelers.map((traveler) => this.toTraveler(traveler))
	}

	static toSubscription(apiSubscription: ApiSubscription): Subscription {
		return { isSubscribed: apiSubscription.subscribed }
	}

	static toAvailable(data: ApiAvailability): boolean {
		return data.available
	}
}
