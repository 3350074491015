












































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Dialog, { DialogType } from '~/components/UI/Dialog.vue'
import Form from '~/components/UI/Form.vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import Col from '~/components/UI/Col.vue'
import { Profile } from '~/models/Profile'
import { ProfileService } from '~/passporter-services/profile/service'
export default Vue.extend({
	name: 'ProfileEdit',
	components: { Col, InputText, Form, PButton, Dialog },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: undefined as Profile | undefined,
			validatedForm: false,
			loader: false,
			nameApiError: null as string | null,
			usernameApiError: null as string | null,
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
		}),
		disabledForm(): boolean {
			return !this.validatedForm || this.loader || !!this.usernameApiError
		},
	},
	methods: {
		async open() {
			this.refresh()
			await this.$store.dispatch('auth/setUser')
			this.form = { ...this.user }
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.open()
		},
		close() {
			const dialog: DialogType = this.$refs.dialog as DialogType
			dialog.close()
		},
		refresh() {
			this.clearApiErrors()
			this.validatedForm = false
		},
		async update() {
			this.loader = true

			try {
				if (!this.user.id) return
				const updatedUser = await ProfileService.updateProfile(this.user.id, this.form!)
				this.$store.commit('auth/setUser', updatedUser)
				this.close()
			} catch (e: any) {
				const errors = e.response?.data?.errors
				if (!errors) return
				this.handleInvalidUserName(errors)
			} finally {
				this.loader = false
			}
		},
		checkUserName(username: string) {
			if (!this.form && !username) return
			const formated = username.toLowerCase()
			this.form!.username = formated
			try {
				this.$store.dispatch('auth/availableUserName', formated).then((available) => {
					if (!available) {
						this.usernameApiError = this.$t('common_not_available') as string
					} else {
						this.usernameApiError = null
					}
				})
			} catch (e: any) {}
		},
		clearApiErrors() {
			this.nameApiError = null
			this.usernameApiError = null
		},
		nameValidate(value: string) {
			if (!value) {
				return false
			}
			if (value && value.length < 3) {
				return this.$t('Name_Description_Characters')
			}
			return true
		},
		usernameValidate(v: string) {
			if (!v) {
				return false
			}
			if (v && v.length < 3) {
				return this.$t('E_error_invalid_username')
			}

			return true
		},
		handleInvalidUserName(errors: any) {
			const invalidUsername = errors.find((error: { code: string }) => {
				return error.code === 'invalid_username'
			})
			if (invalidUsername) {
				this.usernameApiError = invalidUsername.detail
			}
		},
	},
})
