








































import Vue from 'vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import InputFile from '~/components/PassporterUI/InputFile.vue'
import PButton from '~/components/PassporterUI/PButton.vue'
import MediaService from '~/passporter-services/media/service'
import FileRestrictions from '~/passporter-services/shared/file'

Vue.directive('click-outside', {
	bind(el: any, binding: any, vnode: any) {
		el.clickOutsideEvent = (event: any) => {
			if (!(el === event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind(el) {
		// @ts-ignore
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
})

export default Vue.extend({
	name: 'ProfilePicture',
	components: { InputFile, PButton, Cover },
	props: {
		picture: {
			type: String,
			default: '',
		},
	},
	data() {
		const defaultImage: string = '~/assets/avatars/avatar-person-map.svg'
		const imageUrl: string = this.picture
		const mediaId: string = ''
		const isMenuOpen: boolean = false
		return {
			imageUrl,
			defaultImage,
			mediaId,
			isMenuOpen,
		}
	},
	methods: {
		async uploadImage(file: File): Promise<void> {
			this.isMenuOpen = false
			const maxAllowedMB: number = 2
			if (FileRestrictions.sizeIsGreaterThan(maxAllowedMB, file.size)) {
				this.$store.dispatch('alerts/setError', this.$t('error_imageUpload'))
				return
			}
			try {
				if (this.mediaId) {
					const triggerChangeEvent: boolean = false
					this.removeImage(triggerChangeEvent)
				}
				const uploadedFile = await MediaService.upload(file)
				this.imageUrl = URL.createObjectURL(file)
				this.mediaId = uploadedFile.id
				this.$emit('change', { imageUrl: this.imageUrl, mediaId: uploadedFile.id })
			} catch (error) {
				this.$store.dispatch('alerts/setError', this.$t('Error_profile_picture_uploaded'))
			}
		},
		removeImage(triggerEvent: boolean = true): void {
			this.isMenuOpen = false
			this.imageUrl = ''
			this.mediaId = ''
			if (triggerEvent) {
				this.$emit('change', undefined)
			}
		},
		openMenu() {
			this.isMenuOpen = true
		},
		closeMenu() {
			this.isMenuOpen = false
		},
	},
})
