














import Vue from 'vue'
import InputText from '~/components/PassporterUI/InputText.vue'

export default Vue.extend({
	name: 'ProfileName',
	components: { InputText },
	props: {
		value: {
			type: String,
			default: '',
		},
		error: {
			type: String,
			default: '',
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const name: string = this.value
		const errorMessage: string = this.error
		return {
			name,
			errorMessage,
		}
	},
	watch: {
		error(newVal): void {
			this.errorMessage = newVal
		},
	},
	methods: {
		emitChange(): void {
			this.$emit('change', this.name)
		},
	},
})
