import Calendar from '../shared/calendar'
import { ApiProfile } from './apiTypes'
import { removeEmptyValues } from '~/models/helpers/removeUndefined'
import { Profile } from '~/models/Profile'

export default class ProfileApiMapper {
	public static toApiAvailability(username: string): string {
		return username.toLocaleLowerCase()
	}

	public static toApiUpdateProfile(profile: Profile): ApiProfile {
		const result = {
			username: profile.username,
			name: profile.name,
			country_id: profile.country,
			birth_date: profile.birthYear && Calendar.getFirstDateOf(profile.birthYear),
			picture_id: profile.avatarId,
		} as ApiProfile
		return removeEmptyValues(result) as ApiProfile
	}
}
