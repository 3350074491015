














import Vue from 'vue'
import InputText from '~/components/PassporterUI/InputText.vue'

export interface UserProfile {
	name: string
	username: string
}

export default Vue.extend({
	name: 'Username',
	components: { InputText },
	props: {
		value: {
			type: String,
			default: '',
		},
		error: {
			type: String,
			default: '',
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const username: string = this.value
		const isAvailable: boolean = false
		const errorMessage: string = this.error
		return {
			username,
			isAvailable,
			errorMessage,
		}
	},
	watch: {
		error(newVal): void {
			this.errorMessage = newVal
		},
	},
	methods: {
		emitChange(): void {
			this.$emit('change', this.username)
		},
		printSuccess(): string {
			if (!this.isAvailable) return ''
			return this.$t('common_available').toString()
		},
	},
})
