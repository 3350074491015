import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import Agency from '~/models/Agency'
import BookMark from '~/models/BookMark'
import { Destination2 as Destination } from '~/models/Destination'
import ItineraryCategory from '~/models/ItineraryCategory'
import ItineraryGroup from '~/models/ItineraryGroup'
import ItinerarySpot from '~/models/ItinerarySpot'
import { Marker2 as Marker } from '~/models/Marker'
import Traveler from '~/models/Traveler'
import { SpotsRequest } from '~/repositories/ItineraryRepository'
import { RootState } from '~/store/index'
import { SpotEntityMapper } from '~/passporter-services/spot/entityMapper'
import { DestinationMapperEntity } from '~/passporter-services/destination/toEntity'
import { ItineraryService } from '~/passporter-services/itinerary/service'
import { Itinerary2 as Itinerary, ItineraryForm, AccessType } from '~/models/Itinerary'
import { SpotService } from '~/passporter-services/spot/service'
import { DestinationService } from '~/passporter-services/destination/service'
import { ProfileService } from '~/passporter-services/profile/service'

const initState = () => ({
	searchResults: [],
	history: [] as Itinerary[],
	lastSearch: '',
	itinerary: undefined as Itinerary | undefined,
	itineraryInvitationLink: undefined,
	duplicateDialogDisplayed: false,
	categories: [],
	cover: '',
})
export const state = initState
export type ItineraryModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ItineraryModuleState> = {
	setSearchResults(state, searchResults) {
		state.searchResults = searchResults
	},
	setSearch(state, lastSearch) {
		state.lastSearch = lastSearch
		state.searchResults = []
	},
	pushHistory(state, lastHistory: Itinerary) {
		if (lastHistory) {
			state.history = state.history.slice(0, 8)
			state.history.unshift(lastHistory)
		}
	},
	setItinerary(state, itinerary: Itinerary) {
		state.itinerary = itinerary
	},
	setItineraryOptions(
		state,
		options: {
			isVisible: boolean
			isEditable: boolean
			isRemovable: boolean
			isTrip: boolean
			isDiscover: boolean
			isBookings: boolean
			role: string
			access: string
		}
	) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'isVisible', options.isVisible)
			Vue.set(state.itinerary, 'isEditable', options.isEditable)
			Vue.set(state.itinerary, 'isRemovable', options.isRemovable)
			Vue.set(state.itinerary, 'isTrip', options.isTrip)
			Vue.set(state.itinerary, 'isDiscover', options.isDiscover)
			Vue.set(state.itinerary, 'isBookings', Boolean(options.isBookings))
			Vue.set(state.itinerary, 'role', options.role)
			Vue.set(state.itinerary, 'access', options.access as AccessType)
		}
	},

	setItineraryDates(state, { startDate, endDate }: { startDate: string; endDate: string }) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'startDate', startDate)
			Vue.set(state.itinerary, 'endDate', endDate)
		}
	},
	setItineraryAgency(state, { itinerary, agency }: { itinerary?: Itinerary; agency?: Agency }) {
		const _itinerary = itinerary || state.itinerary
		if (_itinerary) {
			Vue.set(_itinerary, 'agency', agency)
		}
	},
	setItineraryMarkers(state, markers: Marker[]) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'markers', markers)
		}
	},
	setItineraryGroups(state, { itineraryGroups }: { itineraryGroups: ItineraryGroup[] }) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'groups', itineraryGroups)
		}
	},
	setItineraryDestinations(
		state,
		{ itineraryDestinations }: { itineraryDestinations: Destination[] }
	) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'destinations', itineraryDestinations)
		}
	},
	setItinerarySpotsGroupId(state, spotsGroupId: Itinerary['spotsGroupId']) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'spotsGroupId', spotsGroupId)
		}
	},
	setItineraryCategories(state, categories: ItineraryCategory[]) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'categories', categories)
		}
	},
	setItinerarySpots(state, { nextPage, spots }: { nextPage: number; spots: ItinerarySpot[] }) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'spots', spots)
			Vue.set(state.itinerary, 'spotsNextPage', nextPage)
		}
	},
	setItineraryRecommendedSpots(
		state,
		{ nextPage, spots }: { nextPage: number; spots: ItinerarySpot[] }
	) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'recommendedSpots', spots)
			Vue.set(state.itinerary, 'recommendedSpotsNextPage', nextPage)
		}
	},
	setItineraryBlogs(state, blogs: BookMark[]) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'blogs', blogs)
		}
	},
	setItineraryVideos(state, videos: BookMark[]) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'videos', videos)
		}
	},
	pushItinerarySpots(state, { nextPage, spots }: { nextPage: number; spots: ItinerarySpot[] }) {
		if (state.itinerary) {
			const newItinerary = { ...state.itinerary }
			newItinerary.spotsNextPage = nextPage
			spots.forEach((spot: ItinerarySpot) => {
				if (!newItinerary?.spots?.some((savedSpot) => savedSpot.id === spot.id)) {
					newItinerary?.spots?.push(spot)
				}
			})
			state.itinerary = newItinerary
		}
	},
	setItineraryCreator(state, traveler: Traveler) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'creator', traveler)
		}
	},
	setItineraryTravelers(state, travelers: Traveler[]) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'travelers', travelers)
		}
	},
	setCover(state, cover: string) {
		if (state.itinerary) {
			Vue.set(state.itinerary, 'cover', cover)
		}
	},
	setItineraryInvitationLink(state, invitation) {
		state.itineraryInvitationLink = invitation
	},
	setMarkerChecked({}, { marker, isChecked }) {
		Vue.set(marker, 'isChecked', isChecked)
	},
	setMarkerReference({}, { marker, isReference }) {
		Vue.set(marker, 'isReference', isReference)
	},
	setMarkerData({}, { marker, data }) {
		Vue.set(marker, 'data', data)
	},
	setDuplicateDialogDisplayed(state, displayed) {
		state.duplicateDialogDisplayed = displayed
	},
	setCategories(state, categories) {
		state.categories = categories
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<ItineraryModuleState, RootState> = {
	async search({ commit, state, dispatch }, search) {
		try {
			if (search !== state.lastSearch) {
				commit('setSearch', search)
			}
			if (state.lastSearch && state.lastSearch.length > 2) {
				const searchData = {
					q: state.lastSearch,
					page: 0,
					page_size: 10,
				}
				const res = await this.$repositories.itinerary.search(searchData)
				const { status, data } = res
				if (status === 200) {
					const { results } = data
					const parsedResults = this.$apiModel.itinerary.parseItineraries(results)
					commit('setSearchResults', parsedResults)
					return parsedResults
				}
			} else {
				commit('setSearchResults', [])
			}
		} catch (e) {
			commit('setSearchResults', [])
			await dispatch('error', e, { root: true })
		}
	},
	selected({ commit, state }, itinerary) {
		if (!state.itinerary || itinerary.id !== state.itinerary.id) {
			commit('pushHistory', itinerary)
		}
	},
	async openItinerary(
		{ dispatch, state },
		{
			itinerary,
			itineraryId,
			blank,
			queryTransfer,
			section,
			replace,
		}: {
			itinerary: Itinerary
			itineraryId: string
			blank: boolean
			queryTransfer: boolean
			download: boolean
			section: 'map' | 'discovery' | 'bookings'
			replace: true
		}
	) {
		try {
			const _itineraryId = itinerary?.id || itineraryId || state.itinerary?.id

			if (!_itineraryId) return

			let params = {}
			if (queryTransfer) {
				params = this.$router.currentRoute.query
			}
			const route = this.$navigation.itinerary.getRoute(_itineraryId, params, section)

			if (blank) {
				window.open(`${process.env.BASE_URL}${route}`, '_blank')
			} else if (replace) {
				await this.$router.replace(route)
			} else {
				await this.$router.push(route)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openItineraryDestination(
		{ dispatch, state },
		{
			itinerary,
		}: {
			itinerary?: Itinerary
		} = {}
	) {
		try {
			const _itinerary = itinerary || state.itinerary
			const _destination = _itinerary?.destinations?.[0]
			await this.$router.push(
				`/${this.$i18n.locale}/itineraries/${_itinerary?.id}/destinations/${_destination?.id}`
			)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItinerary(
		{ commit, dispatch, state },
		{
			itineraryId,
			code,
			cover,
		}: {
			itineraryId?: string
			code?: string
			cover?: string
		}
	) {
		try {
			itineraryId = itineraryId || state.itinerary?.id || undefined
			if (itineraryId) {
				const invitationCode = code || state.itinerary?.invitationCode
				const itineraryService = new ItineraryService(this.$repositories.itinerary)
				const parsedItinerary = await itineraryService.getItinerary(itineraryId, invitationCode)
				parsedItinerary.invitationCode = code
				if (cover) {
					parsedItinerary.cover = cover
				}
				commit('setItinerary', parsedItinerary)
				await Promise.all([
					dispatch('getItineraryOptions'),
					dispatch('getItineraryTravelers'),
					dispatch('getItineraryDestinations', state.itinerary?.invitationCode),
				])
				if (parsedItinerary.agencyId) {
					await dispatch('getItineraryAgency')
				}
			}
		} catch (e: any) {
			commit('setItinerary', null)
			if (e.response?.data.code === 'invalid_access_code') {
				e.formErrors = { code: 'invalid_access_code' }
				throw e.formErrors
			} else {
				const errorView = await dispatch('errorView', e, { root: true })
				if (!errorView) {
					await dispatch('error', e, { root: true })
				}
			}
		}
	},
	async getItineraryAllData({ dispatch, state, rootGetters }) {
		try {
			await Promise.all([
				dispatch('getItineraryGroups', state.itinerary?.invitationCode),
				dispatch('getItineraryCategories'),
			])
			await dispatch('getItinerarySpots')
			if (
				state.itinerary?.isEditable ||
				rootGetters['itinerary/userIn'] ||
				(!!state.itinerary?.invitationCode && rootGetters['auth/isAuthenticated'])
			) {
				await dispatch('getTrip', state.itinerary?.invitationCode)
				if (state.itinerary?.isEditable) {
					await dispatch('getItineraryRecommendedSpots', state.itinerary?.invitationCode)
				}
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getFrameItinerary(
		{ commit, dispatch, state },
		{
			itineraryId,
		}: {
			itineraryId?: Itinerary['id']
		}
	) {
		try {
			itineraryId = itineraryId || state.itinerary?.id
			if (itineraryId) {
				const res = await this.$repositories.itinerary.getItinerary({
					itineraryId,
				})
				const { status, data } = res
				if (status === 200) {
					const parsedItinerary = this.$apiModel.itinerary.parseItinerary(data)
					commit('setItinerary', parsedItinerary)
					await dispatch('getItineraryMarkers')
				} else {
					commit('setItinerary', null)
				}
			}
		} catch (e) {
			commit('setItinerary', null)
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryOptions({ commit, dispatch, state }, { itineraryId } = {}) {
		try {
			const id = itineraryId || (state.itinerary && state.itinerary.id)
			const res = await this.$repositories.itinerary.getItineraryOptions(id)
			const { data } = res
			const parsedResults = this.$apiModel.itinerary.parseOptions(data)
			commit('setItineraryOptions', parsedResults)
		} catch (e) {
			commit('setItineraryOptions')
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryAgency({ commit, dispatch, state }, { itinerary } = {}) {
		try {
			const _itinerary = itinerary || state.itinerary
			const res = await this.$repositories.agency.getAgency({
				agencyId: _itinerary.agencyId,
			})
			const { data } = res
			const parsedResults = this.$apiModel.agency.parseAgency(data)
			commit('setItineraryAgency', { agency: parsedResults })
		} catch (e) {
			commit('setItineraryAgency')
			await dispatch('error', e, { root: true })
		}
	},
	async setItinerarySpotsGroupId({ commit, dispatch }, groupId: Itinerary['spotsGroupId']) {
		commit('setItinerarySpots', { nextPage: undefined, spots: undefined })
		commit('setItinerarySpotsGroupId', groupId)
		await dispatch('getItinerarySpots')
	},

	setItineraryCover({ commit }, cover) {
		commit('setCover', cover)
	},
	async createGroup({ dispatch, state }, itineraryGroup: ItineraryGroup) {
		try {
			if (state.itinerary) {
				await this.$repositories.itinerary.createGroup(state.itinerary.id, itineraryGroup)
				this.$mixpanel?.track('Group Created')
				dispatch('getItineraryGroups')
			}
		} catch (e: any) {
			e.formErrors = {}
			const invalidName = e.response.data.errors.find((error: any) => {
				return error.code === 'invalid_text'
			})
			if (invalidName) {
				e.formErrors.name = invalidName.detail
			}
			await dispatch('error', e, { root: true })
		}
	},
	async editGroup({ dispatch }, itineraryGroup: ItineraryGroup) {
		try {
			await this.$repositories.itinerary.editGroup(itineraryGroup)
			dispatch('getItineraryGroups')
		} catch (e: any) {
			e.formErrors = {}
			const invalidName = e.response.data.errors.find((error: any) => {
				return error.code === 'invalid_text'
			})
			if (invalidName) {
				e.formErrors.name = invalidName.detail
			}
			await dispatch('error', e, { root: true })
		}
	},
	async setItineraryGroupOrder(
		{ dispatch },
		{ itineraryGroup, position }: { itineraryGroup: ItineraryGroup; position: number }
	) {
		try {
			await this.$repositories.itinerary.editGroupPosition(itineraryGroup, position)
			dispatch('getItineraryGroups')
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async deleteCurrentGroup({ dispatch, getters }) {
		try {
			dispatch('deleteGroup', getters.itineraryCurrentGroup)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async deleteGroup({ dispatch }, itineraryGroup: ItineraryGroup) {
		try {
			await this.$repositories.itinerary.deleteGroup(itineraryGroup)
			dispatch('getItineraryGroups')
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryGroups({ commit, state }, code) {
		try {
			if (state.itinerary) {
				const res = await this.$repositories.itinerary.getItineraryGroups({
					itineraryId: state.itinerary.id,
					code,
				})
				const { data } = res
				const itineraryGroups = this.$apiModel.itinerary.parseItineraryGroups(data)
				commit('setItineraryGroups', { itineraryGroups })
			}
		} catch (e) {}
	},
	async getItineraryDestinations({ commit, state, dispatch, rootState }, code?: string) {
		try {
			if (state.itinerary) {
				const res = await this.$repositories.itinerary.getItineraryDestinations({
					itineraryId: state.itinerary.id,
					code,
				})
				const { data } = res
				const { results } = data
				const _itineraryDestinations = DestinationMapperEntity.toDestinations(results)
				commit('setItineraryDestinations', {
					itineraryDestinations: _itineraryDestinations,
				})

				if (_itineraryDestinations?.length) {
					if (rootState.auth?.user) {
						dispatch('destination/getDestinationWeather', _itineraryDestinations[0], { root: true })
					}
				}
			}
		} catch (e) {}
	},
	async getItineraryCategories({ commit, dispatch, state }) {
		try {
			if (state.itinerary && state.itinerary.id) {
				const res = await this.$repositories.itinerary.getItineraryCategories({
					itineraryId: state.itinerary.id,
					code: state.itinerary.invitationCode,
				})
				const { data } = res
				const parsedResults = this.$apiModel.itinerary.parseItineraryCategories(data)
				commit('setItineraryCategories', parsedResults)
			}
		} catch (e) {
			commit('setItineraryCategories', [])
			await dispatch('error', e, { root: true })
		}
	},
	async getItinerarySpots({ commit, dispatch, state }, { code, categoryIds } = {}) {
		try {
			if (state.itinerary) {
				await dispatch('getItineraryMarkers', { categoryIds })
				const requestData: SpotsRequest = {
					itineraryId: state.itinerary.id,
					page: 0,
					code: code || state.itinerary.invitationCode,
					itineraryGroupId: state.itinerary.spotsGroupId,
					categoryIds: categoryIds && categoryIds.length ? categoryIds : undefined,
				}
				const { next, results } = await this.$services.itinerary.obtainSpots(requestData)

				commit('setItinerarySpots', {
					nextPage: next,
					spots: results,
				})
			}
		} catch (e) {
			commit('setItinerarySpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryMarkers({ commit, dispatch, state }, { categoryIds } = {}) {
		try {
			if (state.itinerary?.id) {
				const res = await this.$repositories.itinerary.getItineraryMarkers({
					itineraryId: state.itinerary?.id,
					code: state.itinerary?.invitationCode,
					groupId: state.itinerary?.spotsGroupId || undefined,
					categoryIds: categoryIds && categoryIds.length ? categoryIds : undefined,
				})
				const { data } = res
				const parsedResults = SpotEntityMapper.toMarkers(data, ({}) => {
					return true
				})
				commit('setItineraryMarkers', parsedResults)
			}
		} catch (e) {
			commit('setItineraryMarkers', [])
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreItinerarySpots({ commit, state, dispatch }, { code, categoryIds }) {
		try {
			if (state.itinerary && state.itinerary.id) {
				const res = await this.$repositories.itinerary.getItinerarySpots({
					itineraryId: state.itinerary.id,
					page: state.itinerary.spotsNextPage || 0,
					code: code || state.itinerary?.invitationCode,
					categoryIds: categoryIds && categoryIds.length ? categoryIds : undefined,
				})
				const { data } = res
				const { next, results } = data
				const parsedResults = this.$apiModel.itinerary.parseItinerarySpots(results)
				commit('pushItinerarySpots', {
					nextPage: next,
					spots: parsedResults,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryCreator({ commit, dispatch, state }) {
		try {
			const travelerId = state.itinerary?.creatorId
			if (travelerId) {
				const traveler = await ProfileService.getTraveler(travelerId)
				commit('setItineraryCreator', traveler)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraryTravelers({ commit, dispatch, state }, { itineraryId, code } = {}) {
		try {
			const res = await this.$repositories.itinerary.getItineraryTravelers({
				itineraryId: itineraryId || (state.itinerary && state.itinerary.id),
				page: 0,
				code: code || state.itinerary?.invitationCode,
			})
			const { data } = res
			const { results } = data
			const parsedResults = this.$apiModel.traveler.parseTravelers(results)
			commit('setItineraryTravelers', parsedResults)
		} catch (e) {
			commit('setItineraryTravelers', [])
			await dispatch('error', e, { root: true })
		}
	},
	removeRecommendedSpot({ commit, state }, spot) {
		if (state.itinerary?.recommendedSpots) {
			commit('setItineraryRecommendedSpots', {
				nextPage: state.itinerary?.recommendedSpotsNextPage,
				spots: state.itinerary?.recommendedSpots?.filter((s) => s.id !== spot.id),
			})
		}
	},
	async getItineraryRecommendedSpots({ commit, dispatch, state }, code) {
		try {
			if (state.itinerary && state.itinerary.id) {
				const res = await this.$repositories.itinerary.getRecomendedSpots({
					itineraryId: state.itinerary.id,
					page: 0,
					code,
				})
				const { data } = res
				const { next: _next, results: _results } = data
				const _spots = this.$apiModel.spot.parseSpots(_results)
				commit('setItineraryRecommendedSpots', {
					nextPage: _next,
					spots: _spots,
				})
			}
		} catch (e) {
			commit('setItineraryRecommendedSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getTrip({ commit, dispatch, state }, code) {
		try {
			if (state.itinerary && state.itinerary.id) {
				const res = await this.$repositories.itinerary.getTrip({
					itineraryId: state.itinerary.id,
					page: 0,
					code,
				})
				const { data: _data } = res
				const { blogs: _blogs, videos: _videos } = this.$apiModel.itinerary.parseTrip(_data)
				commit('setItineraryBlogs', _blogs)
				commit('setItineraryVideos', _videos)
			}
		} catch (e) {
			commit('setItineraryRecommendedSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async create(
		{ dispatch },
		{
			name,
			startDate,
			endDate,
			isPrivate,
			option,
			destination,
			numDays,
			categoryIds,
		}: ItineraryForm
	) {
		const _name =
			name ||
			(this.$i18n.t('Itinerary_title', {
				destination: destination.name,
			}) as string)
		const _startDate = startDate
		const _endDate = endDate
		const _numDays = numDays
		const _isPrivate = isPrivate
		const _option = option
		const _destinationId = destination.id
		const _categoryIds = categoryIds?.length ? categoryIds : undefined
		try {
			const form = {
				title: _name,
				start_date: _startDate,
				end_date: _endDate,
				num_days: _numDays,
				category_ids: _categoryIds,
				state: _isPrivate ? 'private' : 'public',
				option: _option,
				destinations_ids: _destinationId ? [_destinationId] : undefined,
				recommended_spots: false,
			}

			const response = await this.$repositories.itinerary.create(form)
			dispatch('profile/getItineraries', {}, { root: true })
			return response.data
		} catch (e) {
			await dispatch('error', e, { root: true })
			return undefined
		}
	},
	async setFakeItinerary({ commit }, { destinationId, name }) {
		const destinationService = new DestinationService(this.$repositories.destination)
		const destination = (await destinationService.obtainDestination(destinationId)) as Destination
		const markers = [{ location: destination.location } as Marker]
		const itinerary = {
			id: null,
			name,
			markers,
		} as Itinerary

		commit('setItinerary', itinerary)
	},
	async edit(
		{ dispatch, state, rootState },
		{ name, startDate, endDate, isPrivate, option, coverId, id }: ItineraryForm
	) {
		try {
			const itineraryId = id || (state.itinerary && state.itinerary.id)
			if (itineraryId) {
				await this.$repositories.itinerary.edit(itineraryId, {
					title: name,
					option,
					start_date: startDate === undefined ? null : startDate,
					end_date: endDate === undefined ? null : endDate,
					state: isPrivate ? 'private' : 'public',
					cover_id: coverId === undefined ? null : coverId,
				})
				dispatch('profile/getItineraries', {}, { root: true })
				if (rootState.media?.pendingMedias[0]) {
					const cover = rootState.media?.pendingMedias[0].url
					dispatch('getItinerary', { itineraryId, cover })
				} else {
					dispatch('getItinerary', { itineraryId })
				}
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async editDate({ dispatch, commit, state }, { startDate, endDate }) {
		try {
			const itineraryId = state.itinerary?.id
			if (itineraryId && startDate && endDate) {
				const { data } = await this.$repositories.itinerary.edit(itineraryId, {
					start_date: startDate,
					end_date: endDate,
				})
				const parsedItinerary = this.$apiModel.itinerary.parseItinerary(data)
				commit('setItineraryDates', {
					startDate: parsedItinerary.startDate,
					endDate: parsedItinerary.endDate,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async cloneItinerary({ dispatch, state }, { callback }) {
		try {
			const itineraryId = state.itinerary && state.itinerary.id
			if (itineraryId) {
				const req = await this.$repositories.itinerary.cloneItinerary(itineraryId)
				const { data } = req
				dispatch('profile/getItineraries', {}, { root: true })
				dispatch('openItinerary', {
					itineraryId: data.id,
					queryTransfer: true,
					replace: true,
				})
				if (callback) {
					callback(data.id)
				}
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async setSpotGroup(
		{ dispatch, state },
		{
			itinerary,
			itinerarySpot,
			itineraryGroup,
			itineraryGroupId,
		}: {
			itinerary: Itinerary
			itinerarySpot: ItinerarySpot
			itineraryGroup?: ItineraryGroup
			itineraryGroupId?: ItineraryGroup['id']
		}
	) {
		try {
			const _itineraryId = itinerary?.id || state.itinerary?.id
			const _itineraryGroupId = itineraryGroupId || itineraryGroup?.id
			if (_itineraryId) {
				await this.$repositories.itinerary.aggregateSpots(_itineraryId, [
					{
						spotId: itinerarySpot.id!,
						groupsIds: _itineraryGroupId ? [_itineraryGroupId] : [],
						isReference: itinerarySpot.isReference,
					},
				])
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async addSpotToItinerary({ dispatch }, { itineraryId, spotId, isReference = false }) {
		try {
			const spot = { spotId, isReference }

			await this.$repositories.itinerary.aggregateSpots(itineraryId, [spot])
			dispatch('itinerary/getItinerarySpots', { itineraryId }, { root: true })
			return true
		} catch (e: any) {
			await dispatch('error', e, { root: true })
		}
	},
	showAlert({ dispatch }, { itinerary, seeButton = true }) {
		const successMessage = itinerary
			? this.$i18n.t('Toast_Saved_Spots_To_Itinerary', {
					name: itinerary?.name,
			  })
			: this.$i18n.t('spot_saved_profile')

		const seeAction = {
			text: this.$i18n.t('Common_See'),
			action: () => {
				if (itinerary) {
					dispatch('itinerary/openItinerary', { itinerary }, { root: true })
				}
			},
		}
		const option = seeButton ? seeAction : undefined

		dispatch('alerts/setSuccess', { text: successMessage, option }, { root: true })
	},
	async setSpotOrder({ dispatch, state }, { spotId, index, groupId }) {
		try {
			if (state.itinerary && state.itinerary.id) {
				await this.$repositories.itinerary.setSpotOrder({
					itineraryId: state.itinerary.id,
					spotId,
					index,
					groupId,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async deleteSpot({ dispatch, state }, { itineraryId, id }) {
		try {
			await this.$repositories.itinerary.deleteSpot(
				itineraryId || (state.itinerary && state.itinerary.id),
				id
			)
			dispatch('getItinerarySpots')
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async deleteTraveler({ dispatch, state, rootState }, { itineraryId, travelerId }) {
		try {
			const _itineraryId = itineraryId || (state.itinerary && state.itinerary.id)
			if (_itineraryId) {
				await this.$repositories.itinerary.deleteItineraryTraveler(_itineraryId, travelerId)
				if (travelerId === rootState.auth?.id) {
					dispatch('profile/openMyItineraries', undefined, { root: true })
				} else {
					dispatch('getItineraryTravelers')
				}
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async deleteItinerary({ dispatch, state }, id) {
		try {
			await this.$repositories.itinerary.deleteItinerary(
				id || (state.itinerary && state.itinerary.id)
			)
			dispatch('profile/getItineraries', {}, { root: true })
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	clearItinerary({ commit }) {
		commit('setItinerary', null)
	},
	async invite({ commit, dispatch }, itineraryId) {
		try {
			const res = await this.$repositories.itinerary.invite(itineraryId)
			const { data } = res
			const { url } = data
			commit('setItineraryInvitationLink', url)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async acceptInvitation({ dispatch }, { itineraryId, code }) {
		try {
			await this.$repositories.itinerary.acceptInvitation({
				itineraryId,
				code,
			})
			return true
		} catch (e: any) {
			const errorView = await dispatch('errorView', e, { root: true })
			if (!errorView) {
				await dispatch('error', e, { root: true })
			}
			return !errorView
		}
	},
	async getItineraryMarkerData({ dispatch, state, commit }, marker: Marker) {
		try {
			const spot = state.itinerary?.spots?.find((spot) => spot.id === marker.id)
			if (spot) {
				commit('setMarkerData', { marker, data: spot })
			} else if (marker.id) {
				const spotService = new SpotService(this.$repositories.spot)
				const spotData = await spotService.getSpot(marker.id)
				commit('setMarkerData', { marker, data: spotData })
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async import({ dispatch, state }, file: File) {
		try {
			if (state.itinerary && state.itinerary.id) {
				await this.$repositories.itinerary.import({
					itineraryId: state.itinerary.id,
					file,
				})
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	setDuplicateDialogDisplayed({ commit }, displayed: boolean) {
		commit('setDuplicateDialogDisplayed', displayed ?? true)
	},
	setMarkerChecked({ commit }, { marker, isChecked }: { marker: Marker; isChecked: boolean }) {
		commit('setMarkerChecked', { marker, isChecked })
	},
	reset({ commit }) {
		commit('reset')
	},
	async getCategories({ dispatch, commit, rootState }) {
		try {
			if (rootState.auth?.id) {
				const res = await this.$repositories.itinerary.getCategories()
				const { data } = res
				const parsedResults = this.$apiModel.itinerary.parseItineraryCategories(data)
				commit('setCategories', parsedResults)
			}
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
}

export const getters: GetterTree<ItineraryModuleState, RootState> = {
	searchResults: (state) => {
		return state.searchResults
	},
	history: (state) => {
		return state.history
	},
	itinerary: (state): Itinerary | undefined => {
		return state.itinerary
	},
	destination: (state): Destination | undefined => {
		return state.itinerary?.destinations?.[0]
	},
	userIn: (state, {}, rootState): boolean | undefined => {
		return state.itinerary?.travelers?.some((traveler: any) => traveler.id === rootState.auth?.id)
	},
	itineraryCurrentGroup: (state) => {
		return state.itinerary?.groups?.find((group) => group.id === state.itinerary?.spotsGroupId)
	},
	itineraryInvitationLink: (state) => {
		return state.itineraryInvitationLink
	},
	itineraryTravelers: (state) => {
		if (state.itinerary?.travelers) {
			const travelers = [...state.itinerary?.travelers] ?? []
			return travelers?.sort((a: Traveler, b: Traveler) =>
				a.role === 'owner' ? -1 : b.role === 'owner' ? 1 : 0
			)
		}
		return []
	},
	itineraryOwner: (state) => {
		return state.itinerary?.travelers?.find((traveler) => traveler.role === 'owner')
	},
	duplicateDialogDisplayed: (state) => {
		return state.duplicateDialogDisplayed
	},
	categories: (state) => {
		return state.categories
	},
}
