import { BaseRepository } from '../baseRepository'
import { MediaEntityMapper } from './entityMapper'
import MediaApiMapper from './apiMapper'
import Media from '~/models/Media'

export default class MediaRepository extends BaseRepository {
	private static readonly resource: string = 'v1/medias'
	public static async upload(file: File): Promise<Media> {
		const params = {}
		const headers = {
			'Content-Type': 'multipart/form-data',
		}
		const formData = MediaApiMapper.toApiUploadFile(file)
		const response = await this.post(`${this.resource}/`, formData, params, headers)
		return MediaEntityMapper.toMedia(response)
	}

	public static async deleteMedia(id: string): Promise<void> {
		await this.delete(`${this.resource}/${id}`)
	}
}
