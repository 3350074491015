import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store/index'
import { Spot2 as Spot } from '~/models/Spot'
import Itinerary from '~/models/Itinerary'
import { ProfileService } from '~/passporter-services/profile/service'

const initState = () => ({
	spots: undefined as Spot[] | undefined,
	spotsNextPage: 0 as number,
	nonVisitedSpots: undefined as Spot[] | undefined,
	nonVisitedSpotsNextPage: 0 as number,
	visitedSpots: undefined as Spot[] | undefined,
	visitedSpotsNextPage: 0 as number,
	itineraries: undefined as Itinerary[] | undefined,
	itinerariesNextPage: 0 as number,
	previousItineraries: undefined as Itinerary[] | undefined,
	previousItinerariesNextPage: 0 as number,
	ownItineraries: undefined as Itinerary[] | undefined,
	ownItinerariesNextPage: 0 as number,
	ongoingItineraries: undefined as Itinerary[] | undefined,
	ongoingItinerariesNextPage: 0 as number,
	followingItineraries: undefined as Itinerary[] | undefined,
	followingItinerariesNextPage: 0 as number,
	nextItineraries: undefined as Itinerary[] | undefined,
	nextItinerariesNextPage: 0 as number,
})
export const state = initState
export type ProfileModuleState = ReturnType<typeof state>

export const mutations: MutationTree<ProfileModuleState> = {
	setSpots(state, { nextPage, spots }) {
		state.spotsNextPage = nextPage
		state.spots = spots
	},
	pushSpots(state, { nextPage, spots }) {
		state.spotsNextPage = nextPage
		spots.forEach((spot: Spot) => {
			if (!state.spots) {
				state.spots = [spot]
			} else if (!state.spots.some((savedSpot) => savedSpot.id === spot.id)) {
				state.spots.push(spot)
			}
		})
	},
	setNonVisitedSpots(state, { nextPage, spots }) {
		state.nonVisitedSpotsNextPage = nextPage
		state.nonVisitedSpots = spots
	},
	pushNonVisitedSpots(state, { nextPage, spots }) {
		state.nonVisitedSpotsNextPage = nextPage
		spots.forEach((spot: Spot) => {
			if (!state.nonVisitedSpots) {
				state.nonVisitedSpots = [spot]
			} else if (!state.nonVisitedSpots.some((savedSpot) => savedSpot.id === spot.id)) {
				state.nonVisitedSpots.push(spot)
			}
		})
	},
	setVisitedSpots(state, { nextPage, spots }) {
		state.visitedSpotsNextPage = nextPage
		state.visitedSpots = spots
	},
	pushVisitedSpots(state, { nextPage, spots }) {
		state.visitedSpotsNextPage = nextPage
		spots.forEach((spot: Spot) => {
			if (!state.visitedSpots) {
				state.visitedSpots = [spot]
			} else if (!state.visitedSpots.some((savedSpot) => savedSpot.id === spot.id)) {
				state.visitedSpots.push(spot)
			}
		})
	},
	setItineraries(state, { nextPage, itineraries }) {
		state.itinerariesNextPage = nextPage
		state.itineraries = itineraries
	},
	setOwnItineraries(state, { nextPage, itineraries }) {
		state.ownItinerariesNextPage = nextPage
		state.ownItineraries = itineraries
	},
	setPreviousItineraries(state, { nextPage, itineraries }) {
		state.previousItinerariesNextPage = nextPage
		state.previousItineraries = itineraries
	},
	setOngoingItineraries(state, { nextPage, itineraries }) {
		state.ongoingItinerariesNextPage = nextPage
		state.ongoingItineraries = itineraries
	},
	setFollowingItineraries(state, { nextPage, itineraries }) {
		state.followingItinerariesNextPage = nextPage
		state.followingItineraries = itineraries
	},
	setNextItineraries(state, { nextPage, itineraries }) {
		state.nextItinerariesNextPage = nextPage
		state.nextItineraries = itineraries
	},
	pushItineraries(state, { nextPage, itineraries }) {
		state.itinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.itineraries) {
				state.itineraries = [itinerary]
			} else if (!state.itineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)) {
				state.itineraries.push(itinerary)
			}
		})
	},
	pushFollowingItineraries(state, { nextPage, itineraries }) {
		state.followingItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.followingItineraries) {
				state.followingItineraries = [itinerary]
			} else if (
				!state.followingItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.followingItineraries.push(itinerary)
			}
		})
	},
	pushNextItineraries(state, { nextPage, itineraries }) {
		state.nextItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.nextItineraries) {
				state.nextItineraries = [itinerary]
			} else if (
				!state.nextItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.nextItineraries.push(itinerary)
			}
		})
	},
	pushOngoingItineraries(state, { nextPage, itineraries }) {
		state.ongoingItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.ongoingItineraries) {
				state.ongoingItineraries = [itinerary]
			} else if (
				!state.ongoingItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.ongoingItineraries.push(itinerary)
			}
		})
	},
	pushOwnItineraries(state, { nextPage, itineraries }) {
		state.ownItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.ownItineraries) {
				state.ownItineraries = [itinerary]
			} else if (
				!state.ownItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.ownItineraries.push(itinerary)
			}
		})
	},
	pushPreviousItineraries(state, { nextPage, itineraries }) {
		state.previousItinerariesNextPage = nextPage
		itineraries.forEach((itinerary: Itinerary) => {
			if (!state.previousItineraries) {
				state.previousItineraries = [itinerary]
			} else if (
				!state.previousItineraries.some((savedItinerary) => savedItinerary.id === itinerary.id)
			) {
				state.previousItineraries.push(itinerary)
			}
		})
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<ProfileModuleState, RootState> = {
	async openMySpots({ dispatch }, { query } = {}) {
		try {
			const route = `${this.$i18n.locale}/spots${query}`
			await this.$router.push(`/${route}`)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getSpots({ commit, rootState, dispatch }) {
		try {
			const res = await this.$repositories.profile.spots({
				// @ts-ignore
				id: rootState.auth.id,
				page: 0,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = await this.$apiModel.spot.parseSpots(results)
			await commit('setSpots', {
				nextPage: next,
				spots: parsedResults,
			})
		} catch (e) {
			commit('setSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getNonVisitedSpots({ commit, rootState, dispatch }) {
		try {
			const res = await this.$repositories.profile.spots({
				// @ts-ignore
				id: rootState.auth.id,
				page: 0,
				visited: false,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = await this.$apiModel.spot.parseSpots(results)
			await commit('setNonVisitedSpots', {
				nextPage: next,
				spots: parsedResults,
			})
		} catch (e) {
			commit('setNonVisitedSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreNonVisitedSpots({ commit, rootState, state, dispatch }) {
		try {
			const res = await this.$repositories.profile.spots({
				// @ts-ignore
				id: rootState.auth.id,
				page: state.nonVisitedSpotsNextPage,
				visited: false,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = this.$apiModel.spot.parseSpots(results)
			await commit('pushNonVisitedSpots', {
				nextPage: next,
				spots: parsedResults,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getVisitedSpots({ commit, rootState, dispatch }) {
		try {
			const res = await this.$repositories.profile.spots({
				// @ts-ignore
				id: rootState.auth.id,
				page: 0,
				visited: true,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = await this.$apiModel.spot.parseSpots(results)
			await commit('setVisitedSpots', {
				nextPage: next,
				spots: parsedResults,
			})
		} catch (e) {
			commit('setVisitedSpots', { nextPage: 0, spots: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreVisitedSpots({ commit, rootState, state, dispatch }) {
		try {
			const res = await this.$repositories.profile.spots({
				// @ts-ignore
				id: rootState.auth.id,
				page: state.visitedSpotsNextPage,
				visited: true,
			})
			const { data } = res
			const { next, results } = data
			const parsedResults = this.$apiModel.spot.parseSpots(results)
			await commit('pushVisitedSpots', {
				nextPage: next,
				spots: parsedResults,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openMyItineraries({ dispatch }, { query } = {}) {
		try {
			const route = `${this.$i18n.locale}/itineraries${query || ''}`
			await this.$router.push(`/${route}`)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async openMyProfile({ dispatch }, { query } = {}) {
		try {
			const route = `${this.$i18n.locale}/profile${query || ''}`
			await this.$router.push(`/${route}`)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
			})
			commit('setItineraries', { nextPage: next, itineraries: items })
		} catch (e) {
			commit('setItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.itinerariesNextPage,
			})
			commit('pushItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getOwnItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'own',
			})
			commit('setOwnItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setOwnItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreOwnItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.ownItinerariesNextPage,
				status: 'own',
			})
			commit('pushOwnItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getPreviousItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'previous',
			})
			commit('setPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setPreviousItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMorePreviousItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.previousItinerariesNextPage,
				status: 'previous',
			})
			commit('pushPreviousItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getOngoingItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'ongoing',
			})
			commit('setOngoingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setOngoingItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreOngoingItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.ongoingItinerariesNextPage,
				status: 'ongoing',
			})
			commit('pushOngoingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getFollowingItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'following',
			})
			commit('setFollowingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setFollowingItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async getMoreFollowingItineraries({ commit, rootState, state, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: state.followingItinerariesNextPage,
				status: 'following',
			})
			commit('pushFollowingItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getNextItineraries({ commit, rootState, dispatch }) {
		try {
			const { next, items } = await ProfileService.getItineraries({
				id: rootState.auth!.id!,
				page: 0,
				status: 'next',
			})
			commit('setNextItineraries', {
				nextPage: next,
				itineraries: items,
			})
		} catch (e) {
			commit('setNextItineraries', { nextPage: 0, itineraries: [] })
			await dispatch('error', e, { root: true })
		}
	},
	async reset({ commit }) {
		await commit('reset')
	},
}

export const getters: GetterTree<ProfileModuleState, RootState> = {
	spots: (state) => {
		return state.spots
	},
	moreSpots: (state) => {
		return state.spotsNextPage
	},
	nonVisitedSpots: (state) => {
		return state.nonVisitedSpots
	},
	moreNonVisitedSpots: (state) => {
		return state.nonVisitedSpotsNextPage
	},
	visitedSpots: (state) => {
		return state.visitedSpots
	},
	moreVisitedSpots: (state) => {
		return state.visitedSpotsNextPage
	},
	itineraries: (state) => {
		return state.itineraries
	},
	moreItineraries: (state) => {
		return state.itinerariesNextPage
	},
	ownItineraries: (state) => {
		return state.ownItineraries
	},
	moreOwnItineraries: (state) => {
		return state.ownItinerariesNextPage
	},
	previousItineraries: (state) => {
		return state.previousItineraries
	},
	previousMoreItineraries: (state) => {
		return state.previousItinerariesNextPage
	},
	ongoingItineraries: (state) => {
		return state.ongoingItineraries
	},
	ongoingMoreItineraries: (state) => {
		return state.ongoingItinerariesNextPage
	},
	followingItineraries: (state) => {
		return state.followingItineraries
	},
	followingMoreItineraries: (state) => {
		return state.followingItinerariesNextPage
	},
	nextItineraries: (state): Itinerary[] | undefined => {
		return state.nextItineraries
	},
	nextMoreItineraries: (state) => {
		return state.nextItinerariesNextPage
	},
}
