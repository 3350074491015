
















import Vue from 'vue'

export default Vue.extend({
	name: 'InputFile',
	props: {
		buttonName: {
			type: String,
			required: true,
		},
	},
	methods: {
		openFileExplorer(): void {
			const input = this.$refs.file as HTMLInputElement
			input.click()
		},
		uploadFile(): void {
			const fileInput = this.$refs.file as HTMLFormElement
			const file = fileInput.files[0]
			this.$emit('change', file)
		},
	},
})
