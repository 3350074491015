import { ProfileRepository } from './repository'
import { Itinerary2, ItineraryStatus } from '~/models/Itinerary'
import { Page } from '~/models/PaginatedItems'
import { Profile } from '~/models/Profile'
import Traveler from '~/models/Traveler'

export class ProfileService {
	static async getProfile(id: string): Promise<Profile> {
		return await ProfileRepository.getProfile(id)
	}

	static async getTraveler(id: string): Promise<Traveler> {
		return await ProfileRepository.getTraveler(id)
	}

	static async updateProfile(id: string, profile: Profile): Promise<Profile> {
		return await ProfileRepository.updateProfile(id, profile)
	}

	static async getItineraries({
		id,
		page,
		status = 'all',
	}: {
		id: string
		page: number
		status?: ItineraryStatus
	}): Promise<Page<Itinerary2>> {
		return await ProfileRepository.getItineraries({
			id,
			page,
			status,
		})
	}

	static async isSubscribedToUser(userId: string): Promise<boolean> {
		const subscription = await ProfileRepository.getSubscription(userId)
		return subscription.isSubscribed
	}

	static async subscribeToUser(userId: string): Promise<void> {
		return await ProfileRepository.subscribe(userId)
	}

	static async unsubscribeToUser(userId: string): Promise<void> {
		return await ProfileRepository.unsubscribe(userId)
	}

	static async checkUsernameAvailability(username: string): Promise<boolean> {
		return await ProfileRepository.checkUsernameAvailability(username)
	}

	static async addWishlist(userId: string, wishlist: Array<string>): Promise<void> {
		await ProfileRepository.addWishlist(userId, wishlist)
	}

	static async addVisitedList(userId: string, visitedList: Array<string>): Promise<void> {
		await ProfileRepository.addVisitedList(userId, visitedList)
	}
}
