






















































import Vue from 'vue'
import InputText from '~/components/PassporterUI/InputText.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'

let debounceTimeout: any = null

export default Vue.extend({
	name: 'Browser',
	components: {
		InputText,
		PIconChevron,
	},
	props: {
		label: {
			type: String,
			default: undefined,
		},
		error: {
			type: String,
			default: undefined,
		},
		isMobile: {
			type: Boolean,
			required: true,
		},
		small: {
			type: Boolean,
			required: false,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		value: {
			type: String,
			default: undefined,
		},
		keepSelected: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const showResults = false as boolean
		const isDialog = false as boolean

		return {
			showResults,
			isDialog,
		}
	},
	computed: {
		searchText: {
			get(): any {
				return this.value
			},
			set(newValue: string) {
				this.$emit('input', newValue)
				return newValue
			},
		},
	},
	methods: {
		inputText(text: string) {
			if (text.length < 3) return this.$emit('clear')
			if (debounceTimeout) {
				clearTimeout(debounceTimeout)
			}
			debounceTimeout = setTimeout(() => {
				this.$emit('browse', text)
			}, 400)
		},
		onFocus() {
			if (this.isMobile) this.isDialog = true
			this.showResults = true
		},
		onFocusOut() {
			setTimeout(() => {
				this.isDialog = false
				if (!this.keepSelected) {
					this.searchText = undefined
				}
				this.showResults = false
				this.$emit('clear')
				this.$emit('focusout')
			}, 300)
		},
	},
})
