
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { CookieService } from '~/passporter-services/cookies'
import { AbTestService } from '~/passporter-services/abTest/service'

export default Vue.extend({
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			userId: 'auth/userId',
		}),
	},
	async mounted() {
		await this.initAbTest()
	},
	methods: {
		async initAbTest() {
			if (!this.isAuthenticated) return
			const groupFlags = await this.getFlagGroups()
			const userGroup = this.getUserGroup(groupFlags)
			if (!userGroup) return
			this.setFeatureFlags(groupFlags[userGroup])
			await this.initTracking(userGroup)
		},
		async getFlagGroups(): Promise<Record<string, string[]>> {
			const service = new AbTestService(this.$repositories.abtest)
			const flagGroups = await service.getUserGroups()
			return flagGroups
		},

		getUserGroup(groupFlags: Record<string, string[]>) {
			let group = CookieService.getCookie('discovery_group')
			if (!group) group = this.setUserGroup(groupFlags)
			return group
		},
		setFeatureFlags(flags: string[]) {
			if (!flags) return
			const featureFlags: Record<string, boolean> = {}
			flags.forEach((flag) => (featureFlags[flag] = true))
			this.$store.dispatch('abtest/setFlags', featureFlags)
		},

		setUserGroup(groupFlags: Record<string, string[]>) {
			const groups = Object.keys(groupFlags)
			const random = Math.floor(Math.random() * groups.length)
			const group = groups[random]

			CookieService.setCookie('discovery_group', group, 30)

			return group
		},

		async initTracking(userGroup: string) {
			await this.$store.dispatch('abtest/setUserGroup', userGroup)
			await this.$mixpanel.identify(this.userId)
			await this.$mixpanel.setUserProperties({ testing_group_web: userGroup })
		},
	},
	render(): any {
		return ''
	},
})
