



















import Vue from 'vue'
import GoogleResultList from '~/components/context/shared/browser/GoogleResultList.vue'
import Browser from '~/components/context/shared/browser/Browser.vue'
import { GooglePlace } from '~/models/GPlace'
import { Spot2 } from '~/models/Spot'
import { SpotService } from '~/passporter-services/spot/service'
import { DestinationService } from '~/passporter-services/destination/service'
import { BrowserService } from '~/services/browser/service'
import { Destination2 } from '~/models/Destination'

export default Vue.extend({
	name: 'GoogleBrowser',
	components: { Browser, GoogleResultList },
	props: {
		value: {
			type: String,
			default: undefined,
		},
		isMobile: {
			type: Boolean,
			required: true,
		},
		types: {
			type: Array,
			default: undefined,
		},
		label: {
			type: String,
			default: undefined,
		},
		keepSelected: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: undefined,
		},
		small: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
	},
	data() {
		const predictions: GooglePlace[] = []
		const placeName: string = this.value
		const selectedPlace: Spot2 | Destination2 = {} as Spot2 | Destination2
		const errorMessage: string = this.error
		return { predictions, placeName, errorMessage, selectedPlace }
	},
	computed: {
		spotService() {
			return new SpotService(this.$repositories.spot)
		},
		destinationService() {
			return new DestinationService(this.$repositories.destination)
		},
	},
	watch: {
		error(newVal): void {
			this.errorMessage = newVal
		},
	},
	methods: {
		async browse(text: string) {
			if (!text) return
			let types
			if (this.types) types = this.types as string[]
			this.predictions = await BrowserService.getPlacesPredictions(text, types)
		},

		async selectPlace(prediction: GooglePlace) {
			let place: Spot2 | Destination2 | undefined
			try {
				place = await this.getDestination(prediction.placeId)
			} catch {
				place = await this.getSpot(prediction.placeId)
			}
			if (!place) {
				return this.$store.dispatch('alerts/SetError', this.$t('error_destination_not_found'))
			}
			if (this.keepSelected) {
				this.placeName = place.name
			}
			this.selectedPlace = place
			this.$emit('select', place)
		},

		clearPredictions() {
			this.predictions = []
		},

		async getDestination(placeId: string) {
			return await this.destinationService.find(placeId)
		},

		async getSpot(placeId: string) {
			let spot: Spot2 | undefined
			try {
				spot = await this.spotService.find(placeId)
			} catch {}
			return spot
		},

		focusOut() {
			if (this.keepSelected && this.selectedPlace) {
				this.placeName = this.selectedPlace.name
			}
		},
	},
})
